@import "../node_modules/@syncfusion/ej2-material-theme/styles/material.css";
/* You can add global styles to this file, and also import other style files */
@import "ngx-toastr/toastr";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "https://cdn.jsdelivr.net/npm/suneditor@latest/dist/css/suneditor.min.css";

@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-angular-calendars/styles/material.css";

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif !important;
  font-family: "Merriweather", serif;
  font-size: 14px;
}

.no-data {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: #999999;
}

.invalid-feedback {
  display: block;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  /* Add blur effect */
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-overlay2 {
  // position: fixed;
  // top: 0;
  // left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  /* Add blur effect */
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: #00a948;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loading-text {
  color: #000000;
  font-size: 14px;
  margin-top: 10px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.badge-modified {
  padding: 5px 13px;
  border-radius: 15px;
}

.btn-close {
  --tblr-btn-close-focus-shadow: none !important;
}

// Date Picker z-index
::ng-deep #cdk-overlay-0 {
  z-index: 3000 !important;
}

::ng-deep .cdk-overlay-connected-position-bounding-box {
  z-index: 3000 !important;
}

::ng-deep .cdk-overlay-container {
  z-index: 3000 !important;
}

// Time Picker z-index

::ng-deep .timepicker-backdrop-overlay {
  z-index: 3000 !important;
}

::ng-deep .timepicker {
  z-index: 3000 !important;
}

::ng-deep .timepicker-overlay {
  z-index: 3000 !important;
}

.btn-close {
  --tblr-btn-close-focus-shadow: none !important;
}

.sticky-header {
  position: sticky;
  top: 0;
}

.table-continer {
  overflow: auto;
}

// *::-webkit-scrollbar{
//   display: none;
// }
// .overflow-auto{
//   scrollbar-width: none;
// }

// print

.print-title {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.print-header {
  padding-top: 20px;
}

.print-sub-text {
  font-weight: 500;
  padding-bottom: 5px;
}

.print-sub-content {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

@media print {
  .page-break {
    page-break-before: always;
  }
}

.obj-name {
  text-align: center;
  padding: 30px 0 0 0;
  font-size: 16px;
  font-weight: 600;
}

// print
.reviewSidePanel.show {
  width: 300px !important;
  z-index: 1500 !important;
}

.secondary-font {
  font-family: "Inter Var", -apple-system, BlinkMacSystemFont, San Francisco,
    Segoe UI, Roboto, Helvetica Neue, sans-serif;
}

.overflow-auto {
  scrollbar-width: thin;
}
